






























































































import { Component, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import MovementsViewModel from '@/vue-app/view-models/allianz-dashboard/movements-view-model';
import RangePicker from '@/vue-app/components/RangePicker.vue';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';

@Component({
  name: 'Movements',
  filters: { currencyFormat },
  components: { LoadingDataCard, RangePicker },
})
export default class Movements extends Vue {
  movements_view_model = Vue.observable(new MovementsViewModel());

  changeDates = async (range_of_dates: Array<string>) => {
    this.movements_view_model.dates_selected = range_of_dates;
    await this.movements_view_model.loadMovements();
  }

  async created() {
    await this.movements_view_model.initialize();
  }
}
