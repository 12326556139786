import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Infrastructure
import MovementPresenter
  from '@/modules/my-investment/allianz/allianz-account-movement-report/infrastructure/presenters/movement-presenter';

// Application
import {
  SearchAllianzAccountMovementsReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-movement-report/application/queries';
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';

// Domain
import {
  MovementEntity,
} from '@/modules/my-investment/allianz/allianz-account-movement-report/domain/entities/movement-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class MovementsViewModel {
  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_MOVEMENTS_REPORT_QUERY)
  private readonly search_allianz_account_movements_report!:
    SearchAllianzAccountMovementsReportQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly customer_id = sessionStorage.getItem('user_id');

  readonly i18n_namespace = 'components.allianz-dashboard.movements';

  readonly sort_spec = [
    {
      field: 'movement_date',
      direction: 'desc',
      nulls: 'first',
    },
  ];

  readonly pagination_spec = {
    page: 1,
    page_size: 10,
    all_items: true,
  };

  error_message = i18n.tc('components.allianz-dashboard.errors.try_again.error_message');

  is_loading = true;

  dates_selected = ['2022-02-15', '2022-03-25'];

  movements_options = [
    {
      movement_type_code: 'all',
      value: 'Todos los movimientos',
    },
    {
      movement_type_code: 'AA',
      value: 'Depósito',
    },
    {
      movement_type_code: 'AP',
      value: 'Aportación Inicial',
    },
    {
      movement_type_code: 'TR',
      value: 'Traspaso Parcial',
    },
    {
      movement_type_code: 'TT',
      value: 'Traspaso Total',
    },
    {
      movement_type_code: 'RP',
      value: 'Retiro Parcial',
    },
    {
      movement_type_code: 'RT',
      value: 'Retiro Total',
    },
  ];

  movement_option_selected = this.movements_options[0].movement_type_code;

  headers = [
    {
      text: 'icon',
      value: 'name',
    },
    {
      text: 'Tipo',
      value: 'type',
    },
    {
      text: 'Monto',
      value: 'amount',
    },
  ];

  movements: Array<MovementEntity> = [];

  filter_params = {
    sort_spec: this.sort_spec,
    pagination_spec: this.pagination_spec,
    query_spec: {},
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  createPresenter = (movement: MovementEntity) => new MovementPresenter(movement);

  loadFilterParams = () => {
    const query_spec = [
      {
        and: [
          {
            field: 'customer_id',
            op: 'eq',
            value: this.customer_id,
          },
        ],
      },
    ];

    if (this.movement_option_selected && this.movement_option_selected !== 'all') {
      query_spec[0].and.push({
        field: 'movement_type_code',
        op: 'eq',
        value: this.movement_option_selected,
      });
    }

    if (this.dates_selected[0] && this.dates_selected[1]) {
      query_spec[0].and.push({
        field: 'movement_date',
        op: 'ge',
        value: this.dates_selected[0],
      });
      query_spec[0].and.push({
        field: 'movement_date',
        op: 'le',
        value: this.dates_selected[1],
      });
    }

    return query_spec;
  }

  loadMovements = async () => {
    try {
      this.filter_params.query_spec = this.loadFilterParams();
      this.movements = await this.search_allianz_account_movements_report
        .execute(this.filter_params);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_movements'));
    }
  }

  calculateFirstRangeOfDates = (current_date: Date) => {
    const from_date = new Date(current_date.getTime());
    from_date.setDate(current_date.getDate() - 90);
    const to_date = new Date(current_date.getTime());
    return [
      from_date.toISOString().split('T')[0],
      to_date.toISOString().split('T')[0],
    ];
  }

  initialize = async () => {
    this.dates_selected = this.calculateFirstRangeOfDates(new Date());
    await this.loadMovements();
    this.is_loading = false;
  }
}
