import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Domain
import { MovementEntity } from '../../domain/entities/movement-entity';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Inject from '@/modules/shared/domain/di/inject';

type MovementStyle = {
  name: string;
  sign: string;
  text_color: string;
  icon: string;
  icon_color: string;
};

const MOVEMENT_TYPES: Record<string, MovementStyle> = {
  AA: {
    name: 'Depósito',
    sign: '+',
    text_color: 'primary',
    icon: 'mdi-plus',
    icon_color: 'secondary',
  },
  AP: {
    name: 'Aportación Inicial',
    sign: '+',
    text_color: 'primary',
    icon: 'mdi-plus',
    icon_color: 'secondary',
  },
  TR: {
    name: 'Traspaso Parcial',
    sign: '',
    text_color: 'primary',
    icon: 'mdi-fast-forward',
    icon_color: 'purple',
  },
  TT: {
    name: 'Traspaso Total',
    sign: '',
    text_color: 'primary',
    icon: 'mdi-fast-forward',
    icon_color: 'purple',
  },
  RP: {
    name: 'Retiro Parcial',
    sign: '',
    text_color: 'error',
    icon: 'mdi-close-thick',
    icon_color: 'error',
  },

  RT: {
    name: 'Retiro Total',
    sign: '',
    text_color: 'error',
    icon: 'mdi-close-thick',
    icon_color: 'error',
  },
};

export default class MovementPresenter {
  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  private readonly movement: MovementEntity;

  constructor(movement: MovementEntity) {
    this.movement = movement;
  }

  get movement_type() {
    return MOVEMENT_TYPES[this.movement.movement_type_code];
  }

  get movement_date() {
    return this.movement.movement_date ? this.date_formatter.formatDate(this.movement.movement_date, 'DD MMM YYYY') : '';
  }

  get movement_type_amount() {
    switch (this.movement.movement_type_code) {
      case 'AA':
        return `${currencyFormat(this.movement.local_amount!)} MXN`;
      case 'AP':
        return `${currencyFormat(this.movement.local_amount!)} MXN`;
      case 'TR':
        return `${currencyFormat(this.movement.local_transfer!)} MXN`;
      case 'RP':
        return `${currencyFormat(this.movement.local_partial_withdrawal!)} MXN`;
      case 'RT':
        return `${currencyFormat(this.movement.local_partial_withdrawal!)} MXN`;
      default:
        return 0;
    }
  }
}
